<script>
export default {
  name: "Resume",
  data() {
    return {
      url: "https://resume.creddle.io/embed/qzqpqjqzqpq",
    };
  },
};
</script>

<template>
  <div style="display: flex; height: 100%; flex-direction: column">
    <div class="url-wrapper">
      <div class="url-label">Address</div>
      <input class="url-bar" v-model="url" />
    </div>
    <div class="ie-base">
      <a href="#" target="_blank">Download</a>
      <!-- <iframe
        class="frame"
        src="https://geankaminski.medium.com/"
      ></iframe> -->
    </div>
  </div>
</template>

<style scoped>
.ie-base {
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.url-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 32px;
}

.url-label {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 6px;
}

.url-bar {
  background-color: #fff;
  width: 100%;
  height: 22px;
  margin-top: 1px;
  padding-top: 1px;
  margin-right: 4px;
  padding-left: 4px;
}

.frame {
  width: 100%;
  height: 97.5%;
}
</style>