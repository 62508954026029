var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar-container"},[_c('div',{staticClass:"start-menu",class:_vm.$store.getters.getActiveWindow == 'Menu'
        ? 'start-menu-depressed'
        : 'start-menu',attrs:{"alt":"start"},on:{"click":function($event){return _vm.$store.commit('setActiveWindow', 'Menu')}}},[_c('div',{class:_vm.$store.getters.getActiveWindow == 'Menu'
          ? 'border'
          : 'container-border'},[_c('img',{staticClass:"start-icon",attrs:{"alt":"start icon","src":require("@/assets/win95/win95.png")}}),_c('button',{staticClass:"start-btn"},[_vm._v("Start")])])]),_vm._l((this.activeWindows),function(window){return _c('div',{key:window.key},[(
        _vm.$store.getters.getActiveWindow !== window.windowId &&
        (window.windowState == 'open' || window.windowState == 'minimize')
      )?_c('button',{staticClass:"navbar-item open",on:{"click":function($event){return _vm.openWindow(window.windowId)}}},[_c('img',{staticClass:"icon-image",attrs:{"src":require('@/assets/win95/' + window.iconImage),"alt":window.altText}}),_c('p',[_vm._v(_vm._s(window.displayName))])]):_vm._e(),(_vm.$store.getters.getActiveWindow == window.windowId)?_c('button',{staticClass:"navbar-item-depressed",on:{"click":function($event){return _vm.openWindow(window.windowId)}}},[_c('img',{staticClass:"icon-image",attrs:{"src":require('@/assets/win95/' + window.iconImage),"alt":window.altText}}),_c('p',[_vm._v(_vm._s(window.displayName))])]):_vm._e()])}),_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"time",attrs:{"alt":"time"}},[_c('img',{staticClass:"icon-image",attrs:{"src":require("@/assets/win95/speakers.png"),"alt":""}}),_c('time',[_vm._v(" "+_vm._s(_vm.time)+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }