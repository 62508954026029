var render = function render(){var _vm=this,_c=_vm._self._c;return _c('interact',{staticClass:"window window-style",class:{
    fullscreen: _vm.$store.getters.getWindowFullscreen(this.ComponentName),
    minimize:
      _vm.$store.getters.getWindowById(_vm.ComponentName).windowState == 'minimize',
  },style:(_vm.style),attrs:{"draggable":"","dragOption":_vm.dragOption,"resizabletop-navbar":""},on:{"dragmove":_vm.dragmove,"resizemove":_vm.resizemove},nativeOn:{"click":function($event){return _vm.setActiveWindow.apply(null, arguments)}}},[_c('div',{staticClass:"top-bar-window",class:_vm.$store.getters.getActiveWindow == this.ComponentName
        ? 'top-bar'
        : 'top-bar-deactivated',attrs:{"id":"top-bar"},on:{"dblclick":_vm.toggleWindowSize}},[_c('div',{staticClass:"window-name"},[_c('img',{staticClass:"icon-image",attrs:{"src":require('@/assets/win95/' + this.window.iconImage),"alt":_vm.window.altText}}),_vm._v(_vm._s(this.window.displayName)+" ")]),_c('div',{staticClass:"triple-button"},[_c('button',{staticClass:"minimize-button button",on:{"click":_vm.minimizeWindow}},[_c('span',{staticStyle:{"height":"2px","width":"6px","background":"black","margin-top":"8px","margin-right":"2px"}})]),_c('button',{staticClass:"expand-button button",on:{"click":_vm.toggleWindowSize}},[_c('span',{staticStyle:{"height":"8px","width":"9px","border-left":"black 1px solid","border-right":"black 1px solid","border-bottom":"black 1px solid","border-top":"black 2px solid"}})]),_c('button',{staticClass:"close-button button",staticStyle:{"margin-right":"3px","padding-left":"1px"},on:{"click":_vm.closeWindow}},[_c('svg',{staticStyle:{"enable-background":"new 0 0 512 512"},attrs:{"height":"512px","id":"Layer_1","version":"1.1","viewBox":"0 0 512 512","width":"512px"}},[_c('path',{attrs:{"d":"M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"}})])])])]),_c('div',{staticClass:"content"},[_vm._t("content")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }