<template>
  <div id="container"></div>
</template>

<script>
import * as Three from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

export default {
  name: "ThreeTest",
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      material: null,
      cubesGroup: null,
      donutsGroup: null,
      textureLoader: null,
      textMesh: null,
      donutMesh: null,
      nearDist: 0.1,
      farDist: 10000,
      cubeSize: 120,
      mouseX: 0,
      mouseY: 0,
      gltfLoader: null,
    };
  },
  methods: {
    init() {
      this.container = document.getElementById("container");

      this.gltfLoader = new GLTFLoader();
      this.textureLoader = new Three.TextureLoader();

      this.camera = new Three.PerspectiveCamera(
        75,
        this.container.clientWidth / this.container.clientHeight,
        this.nearDist,
        this.farDist
      );
      this.camera.position.x = this.farDist * -2;
      this.camera.position.z = 500;

      this.scene = new Three.Scene();

      this.material = new Three.MeshNormalMaterial();

      this.createDonuts();
      this.createCubes();
      this.createLights();
      this.createTypo();
      this.mouseMove();
      this.backgroundLoader();

      this.renderer = new Three.WebGLRenderer({ antialias: true });
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );
      this.container.appendChild(this.renderer.domElement);

      this.render();
    },
    render() {
      requestAnimationFrame(this.render);

      this.camera.position.x += (this.mouseX - this.camera.position.x) * 0.05;
      this.camera.position.y +=
        (this.mouseY * -1 - this.camera.position.y) * 0.05;
      this.camera.lookAt(this.scene.position);

      const t = Date.now() * 0.001;
      const rx = Math.sin(t * 0.7) * 0.5;
      const ry = Math.sin(t * 0.3) * 0.5;
      const rz = Math.sin(t * 0.2) * 0.5;
      if (this.donutsGroup) {
        this.donutsGroup.rotation.x = rx;
        this.donutsGroup.rotation.y = ry;
        this.donutsGroup.rotation.z = rz;
      }
      if (this.cubesGroup) {
        this.cubesGroup.rotation.x = rx;
        this.cubesGroup.rotation.y = ry;
        this.cubesGroup.rotation.z = rz;
      }
      this.textMesh.rotation.x = rx;
      this.textMesh.rotation.y = ry;
      this.textMesh.rotation.z = rx;

      this.renderer.render(this.scene, this.camera);
    },
    updateCoordinates(coordX, coordY) {
      this.mouseX = (coordX - this.mouseFX.windowHalfX) * 10;
      this.mouseY = (coordY - this.mouseFX.windowHalfY) * 10;
    },
    backgroundLoader() {
      this.textureLoader.load("./sky.jpg", (texture) => {
        this.scene.background = texture;
      });
    },
    onMouseMove(e) {
      this.updateCoordinates(e.clientX, e.clientY);
    },
    onTouchMove(e) {
      this.updateCoordinates(
        e.changedTouches[0].clientX,
        e.changedTouches[0].clientY
      );
    },
    mouseMove() {
      this.mouseX = 0;
      this.mouseY = 0;

      this.mouseFX = {
        windowHalfX: this.container.clientWidth / 2,
        windowHalfY: this.container.clientHeight / 2,
      };

      this.$el.addEventListener("mousemove", this.onMouseMove);
      this.$el.addEventListener("touchmove", this.onTouchMove);
    },
    createDonuts() {
      this.gltfLoader.load("./donut.glb", (gltf) => {
        let donutMesh = gltf.scene;
        donutMesh.scale.set(120, 120, 120);

        const dist = this.farDist / 3;
        const distDouble = dist * 2;
        const tau = 2 * Math.PI;

        this.donutsGroup = new Three.Group();

        for (let i = 0; i < 60; i++) {
          const donut = donutMesh.clone();
          donut.position.x = Math.random() * distDouble - dist;
          donut.position.y = Math.random() * distDouble - dist;
          donut.position.z = Math.random() * distDouble - dist;
          donut.rotation.x = Math.random() * tau;
          donut.rotation.y = Math.random() * tau;
          donut.rotation.z = Math.random() * tau;

          donut.matrixAutoUpdate = false;
          donut.updateMatrix();
          this.donutsGroup.add(donut);
        }

        this.scene.add(this.donutsGroup);
      });
    },
    createCubes() {
      const geometry = new Three.BoxBufferGeometry(
        this.cubeSize,
        this.cubeSize,
        this.cubeSize
      );

      this.cubesGroup = new Three.Group();

      for (let i = 0; i < 110; i++) {
        const mesh = new Three.Mesh(geometry, this.material);
        const dist = this.farDist / 3;
        const distDouble = dist * 2;
        const tau = 2 * Math.PI;

        mesh.position.x = Math.random() * distDouble - dist;
        mesh.position.y = Math.random() * distDouble - dist;
        mesh.position.z = Math.random() * distDouble - dist;
        mesh.rotation.x = Math.random() * tau;
        mesh.rotation.y = Math.random() * tau;
        mesh.rotation.z = Math.random() * tau;

        mesh.matrixAutoUpdate = false;
        mesh.updateMatrix();
        this.cubesGroup.add(mesh);
      }
      this.scene.add(this.cubesGroup);
    },
    createLights() {
      const ambientLight = new Three.AmbientLight(0xffffff, 0.7);
      this.scene.add(ambientLight);

      const directionalLight = new Three.DirectionalLight(0xffffff, 0.5);
      directionalLight.position.set(1, 1, 1);
      this.scene.add(directionalLight);
    },
    createTypo() {
      const loader = new FontLoader();
      this.textMesh = new Three.Mesh();
      const createTypo = (font) => {
        const word = "I LOVE \nDONUTS";
        const typoProperties = {
          font: font,
          size: this.cubeSize,
          height: this.cubeSize / 2,
          curveSegments: 12,
          bevelEnabled: true,
          bevelThickness: 10,
          bevelSize: 6,
          bevelOffset: 1,
          bevelSegments: 8,
        };
        const text = new TextGeometry(word, typoProperties);
        this.textMesh.geometry = text;
        this.textMesh.material = this.material;
        this.textMesh.position.x = this.cubeSize * -2;
        this.textMesh.position.z = this.cubeSize * -1;
        this.scene.add(this.textMesh);
      };
      loader.load(
        "https://threejs.org/examples/fonts/helvetiker_regular.typeface.json",
        createTypo
      );
    },
  },
  mounted() {
    this.init();
  },
  destroyed() {
    this.$el.removeEventListener("mousemove", this.onMouseMove);
    this.$el.removeEventListener("touchmove", this.onTouchMove);
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
  outline: none;
}
</style>